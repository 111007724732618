import axios from 'axios';//引入axios
import {
  Message,
} from 'element-ui'
import router from '../router'
// axios.defaults.baseURL = 'http://192.168.1.66:8081/'
axios.defaults.baseURL = 'https://rancho.campgrounds.cn/'
axios.defaults.timeout = 5000;//加载不出来5秒之后就是加载失败
axios.interceptors.request.use(config => {  
  if (!config.url.includes('login')) {
    // console.log(window.sessionStorage.getItem("token"));
    config.headers['token'] = window.sessionStorage.getItem("token")
  } return config
})


// 响应
axios.interceptors.response.use((response) => {
  if (response.data.code == 10001) {
    console.log("您的token已失效");
    Message({
      message: '您的token已失效',
      type: 'error',
    })
    window.sessionStorage.clear();
    router.push({ path: '/login' })
  }
  return response

}, error => {
  // 在此定义请求错误需要做什么
  Message({
    message: '服务器错误',
    type: 'error',
  })

  return Promise.reject(error)
})

// 使用promise返回axios请求
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
};

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err.data)
    })

  })
}