import Vue from 'vue'
import store from "../store/index";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
//登录
import Login from '../views/Login/Login.vue'
import Report from '../views/Home/404.vue'
NProgress.configure({
  easing: 'ease',  // 动画方式    
  speed: 500,  // 递增进度条的速度    
  showSpinner: false, // 是否显示加载ico    
  trickleSpeed: 200, // 自动递增间隔    
})
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    redirect: '/index',
    component: Home,
    children: [
      {
        path: '/index',
        component: () => import("@/views/Home/Index/index.vue"),
        meta: { name: '控制台' }
      },
      {
        path: '/banner',
        component: () => import("@/views/Home/Banner/banner.vue"),
        meta: { name: '轮播图管理' }
      },
      {
        path: '/bannerCart',
        component: () => import("@/views/Home/bannerCart/bnCartList.vue"),
        meta: { name: '轮播图分类管理' }
      },
      {
        path: '/pictureList',
        component: () => import("@/views/Home/picture/pictureList.vue"),
        meta: { name: '广告图列表' }
      },
      {
        path: '/hotSale',
        component: () => import("@/views/Home/HotSale/hotSale.vue"),
        meta: { name: '热卖列表' }
      }, {
        path: '/order',
        component: () => import("@/views/Home/Order/order.vue"),
        meta: { name: '订单列表' }
      }, {
        path: '/orderInfo',
        component: () => import("@/views/Home/Order/orderInfo.vue"),
        meta: { name: '订单详情' }
      }, {
        path: '/recommend',
        component: () => import("@/views/Home/Recommend/recommend.vue"),
        meta: { name: '推荐列表' }
      }, {
        path: '/user',
        component: () => import("@/views/Home/User/user.vue"),
        meta: { name: '用户列表' }
      }, {
        path: '/shopping',
        component: () => import("@/views/Home/Shopping/shopping.vue"),
        meta: { name: '购物车' }
      }, {
        path: '/conver',
        component: () => import("@/views/Home/Conver/conver.vue"),
        meta: { name: '兑换券' }
      },
      {
        path: '/converList',
        component: () => import("@/views/Home/Conver/converList.vue"),
        meta: { name: '兑换券' }
      },
      {
        path: '/getcoupon',
        component: () => import("@/views/Home/Conver/getcoupon.vue"),
        meta: { name: '领券列表' }
      }
    ]
  },
  {
    //机构
    path: '/firm',
    redirect: '/firm',
    component: Home,
    children: [
      {
        path: '/firm',
        component: () => import("@/views/Home/Firm/firm.vue"),
        meta: { name: '机构列表' }
      },  {
        path: '/firm/fconsumer',
        component: () => import("@/views/Home/Firm/fConsumer.vue"),
        meta: { name: '机构消费' }
      },
      {
        path: '/firm/fdetail',
        component: () => import("@/views/Home/Firm/fDetail.vue"),
        meta: { name: '机构详情' }
      },
      {
        path: '/firm/frecdep',
        component: () => import("@/views/Home/Firm/frecdep.vue"),
        meta: { name: '机构充值提现' }
      },
      {
        path: '/firm/fwrite',
        component: () => import("@/views/Home/Firm/fWrite.vue"),
        meta: { name: '审核列表' }
      }
    ]
  },
  {
    //家庭
    path: '/family',
    redirect: '/family',
    component: Home,
    children: [
       {
        path: '/family',
        component: () => import("@/views/Home/Family/family.vue"),
        meta: { name: '家庭列表' }
      }, {
        path: '/family/write',
        component: () => import("@/views/Home/Family/write.vue"),
        meta: { name: '家庭注销列表' }
      }, {
        path: '/family/detail',
        component: () => import("@/views/Home/Family/detail.vue"),
        meta: { name: '家庭详情' }
      },
      {
        path: '/family/consume',
        component: () => import("@/views/Home/Family/consume.vue"),
        meta: { name: '家庭消费' }
      },
      {
        path: '/firm/recdep',
        component: () => import("@/views/Home/Family/recdep.vue"),
        meta: { name: '家庭充值提现' }
      },

    ]
  },
  {
   //会员
   path: '/member',
   redirect: '/member',
   component: Home,
   children: [
      {
       path: '/member',
       component: () => import("@/views/Home/Member/member.vue"),
       meta: { name: '会员等级' }
     }
    ]
  },
  {
    //登录
    path: '/login',
    name: 'login',
    component: Login
  },
  // 404页面
  {
    path: "*",
    component: Report
  }
]
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // store.commit("getroute",{ path:to.path,query:to.query});
  // console.log(store.state.history);
  NProgress.start();
  let token = sessionStorage.getItem('token')
  if (to.path === '/login') {
    // let info = []
    // router.addRoutes(info)
    return next()
  }
  if (!token) {
    next('/login')
  }
  next()
})
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
export default router
